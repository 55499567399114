
import { defineComponent } from 'vue'

export default defineComponent({
    data(){
        return{
            config: []
        }
    },
    mounted() {
        //set config
        this.config = JSON.parse( localStorage.getItem('config') as any[any])
    }
})
